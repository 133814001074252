@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600;700;800&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
* {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  background: linear-gradient(267.81deg, #3c3dbf 1.92%, #2998ff 106.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #454545;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px !important;
  color: #454545;
}

h4 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px;
  color: #3c3dbf !important;
}

h5 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 21px;
  color: #454545;
  text-transform: capitalize;
}

h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #3c3dbf;
}
.title-sm {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px !important;
  color: #a1a1a1;
  text-transform: capitalize;
}
.title-md {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px !important;
  color: #a1a1a1;
}

.card-wrapper {
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 6px 30px rgba(206, 206, 206, 0.25);
  border-radius: 14px;
}

.icon-wrapper-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 35px;
  height: 35px;
  background: #e4e5ff;
  border-radius: 6px;
  color: #3c3dbf;
}

.box-sh {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.5), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2), inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
}

.icon-wrapper-out {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 35px;
  height: 35px;
  background: #ffecf0;
  border-radius: 6px;
  color: #ff3666;
}

.text-ungu {
  color: #3c3dbf;
}

.money-in {
  font-size: 18px;
  font-weight: 600 !important;
  color: #3c3dbf;
}
.money-out {
  font-size: 18px;
  font-weight: 600 !important;
  color: #ff3666;
}

.button {
  height: 37px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.5), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2), inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
  outline: none;
  gap: 10px;
}

.btn-pink {
  background: rgb(240, 9, 171);
  background: linear-gradient(0deg, rgba(240, 9, 171, 1) 0%, rgba(240, 9, 171, 1) 100%);
  border: none;
}

.btn-ungu {
  background: rgb(96, 9, 240);
  background: linear-gradient(0deg, rgba(96, 9, 240, 1) 0%, rgba(129, 5, 240, 1) 100%);
  border: none;
}

.btn-pink:before,
.btn-ungu:before {
  height: 0%;
  width: 2px;
}


.form-control:focus {
  border: none;
  outline: none;
}